import axios from "axios";

function getHeaders(){
  let headers = {};
  let token = localStorage.getItem('token');
  if(token){
    headers.Authorization = `Bearer ${token}`
  }
  return headers;
}
function createClient(){
  let client = axios.create({
    baseURL: 'https://bedivierre.com/api',
    // baseURL: 'http://gatsby-lara.loc/api',
    withCredentials: true,
    headers:getHeaders()
  });
  return client;
}

let apiClient = createClient();
export function updateApiClient(){
  apiClient = createClient();
}
export default apiClient;