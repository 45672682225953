import React from 'react';

function HeaderLine(props) {
  return (
    <div className='d-flex justify-content-between mb-2 header'>
      <div className='p-2 text-size-20'>
        {props.user.name}
      </div>
      <div className='py-2 px-3 text-size-20' onClick={props.toggle}>
        <i className="bi bi-three-dots-vertical"></i>
      </div>
    </div>
  );
}

export default HeaderLine;