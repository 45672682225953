import React from 'react';

function HeaderMenuTitle(props) {
  return (
    <div className='d-flex justify-content-between p-2 mb-3'>
      <div className='text-size-24'>
        {props.text}
      </div>
      <div className='p-1 text-size-24 ml-2' onClick={props.toggle}>
        <i className="bi bi-x-circle"></i>
      </div>
    </div>
  );
}

export default HeaderMenuTitle;