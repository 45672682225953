import React, {Component} from 'react';
import API from "../../utility/gatsby-api";
import DepositList from "./Deposit/DepositList";

class DepositPage extends Component {
  state={
    loading:true,
    depositList:null,
    error:false,
    errorMessage:'',
  }
  constructor() {
    super();
  }
  componentDidMount() {
    this.loadDeposits();
  }


  loadDeposits= () => {
    API.getUserDepositList(data =>{
      console.log(data);
      let state = {loading: false, depositList: null, error: false}
      if(data.status == 'error') {
        state.error = true;
        state.errorMessage = data.message;
      } else {
        state.depositList = data.depositList;
      }
      this.setState(state);
    })
    return '';
  }
  render() {
    if(this.state.loading)
      return (<div>Загрузка данных депозитов</div>);
    if(!this.state.depositList)
      return (<div>Ошибка при получении данных депозитов</div>);
    return (
      <DepositList list={this.state.depositList} />
    );
  }
}

export default DepositPage;