import React from 'react';

function UiDataList(props) {
  return (
    <div className='mb-2'>
      {props.children}
    </div>
  );
}

export default UiDataList;