import './App.css';
import * as React from "react";
import Auth from './components/Auth'
import Header from './components/Main/Header'
import Utility from "./utility/utility";
import API from "./utility/gatsby-api";
import AppLoading from "./AppLoading";
import AppPage from "./AppPage";
import ErrorCode from "./utility/ErrorCode";
import Main from "./components/Main";

class App extends React.Component {
  state = {
    loading:false,
    user:null,
    tick:false,
  }
  constructor() {
    super();
    Utility.onAppUpdate = () =>{
      this.getUserData();
    }
  }
  getUserData(){
    let state = {
      loading:false, user:null
    }
    if(Utility.hasToken()){
      this.setState({loading:true});
      API.getUserData(data=>{
        //unauthorized
        console.log('sss');
        if(data.errorCode == ErrorCode.unauthorized){
          API.logout();
        } else {
          state.user = data.user;
          this.setState(state)
        }
      }, resp =>{
        API.logout();
        this.setState({loading:false, user:null})
      });
    } else {
      this.setState({loading:false, user:null})
    }
  }
  async componentDidMount(){
    this.getUserData();
  }

  render(){
    if(this.state.loading)
      return (<AppLoading />);
    if(!this.state.user)
      return (<Auth />);
    return (
      <Main user={this.state.user} />
    );
  }

}

export default App;
