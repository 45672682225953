import React, {Component} from 'react';
import Header from "./Header";
import Routes from "./Routes";

class Main extends Component {
  render() {
    return (
      <div>
        <Header user={this.props.user} />
        <div className='px-2'>
          <Routes user={this.props.user} />
        </div>
      </div>
    );
  }
}

export default Main;