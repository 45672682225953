import React, {Component} from 'react';
import Utility from "./utility/utility";
import Header from "./components/Main/Header";
import Auth from "./components/Auth";
import Main from "./components/Main";

class AppPage extends Component {
  render() {
    return (
      <div className="App">
        {this.props.user ? <Main user={this.props.user} /> : <Auth />}
      </div>
    );
  }
}

export default AppPage;