class Utility{
  static onAppUpdate = null;
  static appUpdate(handler = null){
    if(typeof (handler) == 'function')
      this.onAppUpdate = handler;
    if(typeof (this.onAppUpdate) == 'function' ){
      this.onAppUpdate()
    }
  }

  static hasToken(){
    return localStorage.getItem('token') != null;
  }
  static getToken(){
    return localStorage.getItem('token');
  }
}


export default Utility;