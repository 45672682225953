import apiClient, {updateApiClient} from "./gapi";
import Utility from "./utility";

const API = {
  _processApiCall:(response ,f_then, f_catch,f_finally,f_before) =>{
    let res = {};
    response.then(result =>{
      res = result.data;
      if(typeof (f_then) == "function")
        f_then(result.data);
    }).catch(result => {
      if(typeof (f_catch) == "function")
        f_catch(result);
    }).then(result => {
      if(typeof (f_finally) == "function")
        f_finally(result);
    });
    return res;
  },
  apiGetCall: (url, f_then, f_catch,f_finally,f_before) =>{
    if(typeof (f_before) == "function")
      f_before();
    return API._processApiCall(apiClient.get(url),
      f_then, f_catch,f_finally,f_before);
  },
  apiPostCall: (url, data, f_then, f_catch,f_finally,f_before) =>{
    if(typeof (f_before) == "function")
      f_before();
    return API._processApiCall(apiClient.post(url, data),
      f_then, f_catch,f_finally,f_before);
  },



  getEvents: (func, f_catch = null, before = null, f_finally = null)=>{
    return API.apiGetCall('/deposit/events.get', func, f_catch, f_finally, before)
  },
  getUserData: (func, f_catch = null, before = null, f_finally = null)=>{
    return API.apiGetCall('/client/data', func, f_catch, f_finally, before)
  },
  getUserDepositList: (func, f_catch = null, before = null, f_finally = null)=>{
    return API.apiGetCall('/client/deposit', func, f_catch, f_finally, before)
  },


  login: (login, password) => {
    let body = {
      phone:login,
      password:password
    }
    API.apiPostCall('/client/login', body,
      data => {
        if(data.token) {
          localStorage.setItem('token', data.token);
          updateApiClient();
          document.location = '/';
          Utility.appUpdate();
        }
      }
      ,(response)=>{
        console.log(response);
        return false;
      }
    );
  },
  logout: () => {
    API.apiGetCall('/client/logout');
    localStorage.removeItem('token');
    updateApiClient();
    Utility.appUpdate();
  }
}
export default API;