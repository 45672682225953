import React from 'react';
import UiDataList from "../../Common/UI/UIDataList";
import UiDataListEntry from "../../Common/UI/UIDataListEntry";

function DepositListEntry(props) {
  let d = props.deposit;
  return (
    <div>
      <hr/>
      <div className='w-100 mb-2'>
        <img src={d.event.image} alt={d.event.name} className='w-100' />
      </div>
      <div className='text-center text-gold text-size-20 mb-2'>{d.event.name}</div>
      <UiDataList>
        <UiDataListEntry name='Цена'>
          {d.discounted_price}₽
          {d.discounted_price != d.price &&
          <span className='text-muted'> ({d.price}₽ - {d.discount}%)</span>}
        </UiDataListEntry>
        {d.discount > 0 &&
          <UiDataListEntry name='Скидка'>
            {d.discount}%
          </UiDataListEntry> }

        <UiDataListEntry name='Место'>
          {d.table.name}
        </UiDataListEntry>
      </UiDataList>

    </div>
  );
}

export default DepositListEntry;