import React, { useState } from "react";
import PropTypes from 'prop-types';
import API from "../../utility/gatsby-api";


class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      phone:'',
      password:'',
    };
  }

  setPhone(phone){
    this.setState({phone: phone});
  }
  setPwd(pwd){
    this.setState({password: pwd});
  }
  submit = async (e) =>{
    e.preventDefault();
    const result = await API.login(this.state.phone,this.state.password);
  }

  render(){
    return (
      <div className="Login">
        <h1>Авторизация</h1>
        <form onSubmit={this.submit}>
          <label>

          </label>
          <div className='mb-3'>
            <div className='mb-1'>Телефон</div>
            <input className='common-input' type="text" onChange={
              (e)=>{this.setPhone(e.target.value)}
            } />
          </div>
          <div className='mb-3'>
            <div className='mb-1'>Пароль</div>
            <input className='common-input' type="password" onChange={
              (e)=>{this.setPwd(e.target.value)}
            } />
          </div>
          <div className='mb-3'>
            <button type="submit" className='default-btn yellow w-100'>Войти</button>
          </div>
          <div className=''>
            Ещё не с нами? <a onClick={this.props.toReg} className='link-golden'>Зарегистрироваться</a>
          </div>
        </form>
      </div>
    );
  }

}

export default Login;
