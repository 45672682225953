import React from 'react';

function UiDataListEntry(props) {
  return (
    <div className='d-flex justify-content-between'>
      <span className='text-bold'>{props.name}: </span>
      <span>{props.children}</span>
    </div>
  );
}

export default UiDataListEntry;