import React from 'react';
import PropTypes from 'prop-types';

HeaderMenuElement.propTypes = {
  link:PropTypes.string,
  text:PropTypes.string.isRequired,
};

function HeaderMenuElement(props) {
  return (
    <div className='text-start px-2 mb-3 text-size-22'>
      {props.icon ?? null}&nbsp;
      <a className='link-light' onClick={props.onClick} href={props.link}>
        <span className='ml-2'>{props.text}</span>

      </a>
    </div>
  );
}

export default HeaderMenuElement;