import * as React from "react";
import PropTypes from "prop-types";
import API from "../../utility/gatsby-api";

class Register extends React.Component {


  constructor() {
    super();
    this.state = {
      phone:'',
      password:'',
      passwordConfirm:''
    };
  }

  setPhone(phone){
    this.setState({phone: phone});
  }
  setPwd(pwd){
    this.setState({password: pwd});
  }
  setPwdConfirm(pwd){
    this.setState({passwordConfirm: pwd});
  }

  submit = async (e) =>{
    e.preventDefault();
    const result = await API.login(this.state.phone,this.state.password);
  }
  render(){
    return (
      <div className="Register">
        <h1>Регистрация</h1>
        <form>
          <div className='mb-3'>
            <div className='mb-1'>Телефон</div>
            <input className='common-input' type="text" onChange={
              (e)=>{this.setPhone(e.target.value)}
            } />
          </div>
          <div className='mb-3'>
            <div className='mb-1'>Пароль</div>
            <input className='common-input' type="password" onChange={
              (e)=>{this.setPwd(e.target.value)}
            } />
          </div>
          <div className='mb-3'>
            <div className='mb-3'>Подтвердите пароль</div>
            <input className='common-input' type="password" onChange={
              (e)=>{this.setPwdConfirm(e.target.value)}
            } />
          </div>
          <div className='mb-3'>
            <button className='default-btn yellow w-100' type="submit">Зарегистрироваться</button>
          </div>
          <div>
            Уже с нами? <a onClick={this.props.toLogin} className='link-golden'>Войти</a>
          </div>
        </form>
      </div>
    );
  }

}

export default Register;
