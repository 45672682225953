import React, {Component} from 'react';
import HeaderMenuTitle from "./HeaderComponents/HeaderMenuTitle";
import HeaderMenuElement from "./HeaderComponents/HeaderMenuElement";
import API from "../../utility/gatsby-api";

class HeaderMenu extends Component {
  render() {
    return (
      <div className={ 'header__menu' +  (this.props.active ? " open" : '')}>
        <HeaderMenuTitle text={this.props.user.name} toggle={this.props.toggle} />
        <HeaderMenuElement text='Профиль' link="/"
          icon={<i className="bi bi-person-fill"></i>} />
        <HeaderMenuElement text='События' link="/events"
          icon={<i className="bi bi-calendar3"></i>}/>
        <HeaderMenuElement text='Депозиты' link="/deposit"
          icon={<i className="bi bi-card-checklist"></i>}/>
        <div className='mb-3' />
        <HeaderMenuElement onClick={() =>{API.logout()}} text='Выход'
          icon={<i className="bi bi-box-arrow-left"></i>}/>
      </div>
    );
  }
};

export default HeaderMenu;