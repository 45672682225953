import React from 'react';
import BarMap from "../../Common/BarMap/BarMap";
import BarMapPlace from "../../Common/BarMap/BarMapPlace";
import DepositListEntry from "./DepositListEntry";

function DepositList(props) {
  return (
    <div className='my-4'>
      <div className='text-center text-font-logo text-size-20 mb-3'>
          Список депозитов
      </div>
      <div>
        {props.list.map(i =>{
          return (<DepositListEntry key={i.id} deposit={i} />)
        })}
      </div>

    </div>
  );
}

export default DepositList;