import logo from '../../logo.svg';
import '../../App.css';
import * as React from "react";
import API from "../../utility/gatsby-api";
import HeaderMenu from "./HeaderMenu";
import HeaderLine from "./HeaderLine";

class Header extends React.Component {
  state = {
    menuOpen:false,
  }
  constructor() {
    super();
  }

  async componentDidMount(){
  }
  toggleMenu = () =>{
    this.setState({menuOpen:!this.state.menuOpen});
  }

  render(){
    return (
      <div>
        <HeaderLine user={this.props.user} toggle={this.toggleMenu} />
        <HeaderMenu active={this.state.menuOpen} toggle={this.toggleMenu} user={this.props.user} />
      </div>
    )
  }

}
export default Header;