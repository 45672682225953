import React from 'react';

function AppLoading(props) {
  return (
    <div className='text-center'>
      Загрузка...
    </div>
  );
}

export default AppLoading;