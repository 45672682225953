import * as React from "react";
import Login from './Login'
import Register from './Register'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

class Auth extends React.Component {
  state={
    type:'login',
  }

  toggleType = () =>{
    let t = this.state.type == 'login' ? 'register' : 'login';

    this.setState({type:t})
  }

  render(){
    return (
      <div className="Auth text-center px-2 mt-px-35">
        {this.state.type == 'login' ?
          <Login toReg={this.toggleType} />
          : <Register toLogin={this.toggleType} />}
      </div>
    );
  }

}

export default Auth;
