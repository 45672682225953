import React, {Component} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Login from "../Auth/Login";
import Register from "../Auth/Register";
import ProfilePage from "../Pages/ProfilePage";
import EventsPage from "../Pages/EventsPage";
import DepositPage from "../Pages/DepositPage";

class Routes extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <ProfilePage user={this.props.user} />
          </Route>
          <Route exact path="/events">
            <EventsPage user={this.props.user} />
          </Route>
          <Route exact path="/deposit">
            <DepositPage user={this.props.user} />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Routes;